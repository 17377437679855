import {RouteConfigSingleView} from "vue-router/types/router";
import {toTitleCase} from "@/utils";
import {Role} from "@/models/id/role";

export interface PageConfig extends RouteConfigSingleView {
  requiresAuth?: boolean;
  showInNavbar?: boolean;
  subpages?: Page[];
  // Page menu will be visible to only members of one of the specified roles. If not specified, it will be visibile
  // to all.
  visibility?: Role[];
}

const defaultPageConfig: PageConfig = {
  path: "/",
  requiresAuth: true,
  showInNavbar: true,
};

let pageId = 0;

export class Page {
  public route: PageConfig;
  public children: Page[] = [];
  public readonly id: number;

  public constructor(route: PageConfig) {
    this.route = Object.assign({}, defaultPageConfig, route);
    this.id = pageId++;
    if (route.subpages) {
      this.children = route.subpages;
    }
  }

  public getUCName(): string {
    if (this.route.name !== null && this.route.name !== undefined) {
      return toTitleCase(this.route.name);
    } else {
      return "";
    }
  }
}

export const homepage = new Page({
  path: "/",
  name: "home",
  component: () => import("@/views/Home.vue"),
});

const rnd = new Page({
  path: "/rd",
  name: "R&D",
  component: () => import("@/views/wfpc/Home.vue"),
  subpages: [
    new Page({
      path: "/rd/mi",
      name: "manufacturing instructions",
      component: () => import("@/views/wfpc/form/MiView.vue"),
      visibility: [Role.MIOnlineAdministrators],
      subpages: [
        new Page({
          path: "/rd/mi/direct",
          name: "direct scan",
          component: () => import("@/views/wfpc/Scan.vue"),
        }),
        new Page({
          path: "/rd/mi/edit",
          name: "new mi",
          component: () => import("@/views/wfpc/form/MiFormView.vue"),
        }),
        new Page({
          path: "/rd/mi/:id([0-9a-f]{24})",
          name: "mi summary",
          showInNavbar: false,
          component: () => import("@/views/wfpc/form/view/Summary.vue"),
          subpages: [
            new Page({
              path: "/rd/mi/:rec([0-9a-f]{24})/:rev([0-9a-f]{24}|draft)",
              name: "mi view",
              showInNavbar: false,
              component: () => import("@/views/wfpc/form/view/View.vue"),
            }),
          ],
        }),
        new Page({
          path: "/rd/setup/home",
          name: "parameters setup",
          component: () => import("@/views/wfpc/setup/Home.vue"),
        }),
        new Page({
          path: "/rd/setup/enums",
          showInNavbar: false,
          component: () => import("@/views/wfpc/setup/Enumeration.vue"),
        }),
        new Page({
          path: "/rd/miAdmin",
          name: "mi administration",
          component: () => import("@/views/wfpc/Administration.vue"),
        }),
      ],
    }), /*
    new Page({
      path: "/rd/mi/testSvg",
      name: "test svg",
      component: () => import("@/views/wfpc/form/testSvg/test.vue"),
    }),
    new Page({
      path: "/rd/mi/testStepRepeat",
      name: "test step repeat",
      component: () => import("@/views/wfpc/form/testStepRepeat/test.vue"),
    }), */
    new Page({
      path: "/rd/product_specs",
      name: "product specifications",
      component: () => import("@/views/rd/productSpecs/List.vue"),
      subpages: [
        new Page({
          path: "/rd/product_specs/history/:itemId",
          name: "product specs history",
          component: () => import("@/views/rd/productSpecs/History.vue"),
          showInNavbar: false,
        }),
        new Page({
          path: "/rd/product_specs/admin",
          name: "product specs administration",
          component: () => import("@/views/rd/productSpecs/Administration.vue"),
        }),
        new Page({
          path: "/rd/product_specs/edit",
          name: "new product specs",
          component: () => import("@/views/rd/productSpecs/editor/Main.vue"),
        }),
      ],
    }),
    new Page({
      path: "/rd/packing",
      name: "packing standards",
      component: () => import("@/views/rd/packing/Packing.vue"),
    }),
    new Page({
      path: "/rd/rfq/prices",
      name: "rfq pricing",
      component: () => import("@/views/rd/rfq/Prices.vue"),
      subpages: [
        new Page({
          path: "/rd/rfq/rawmatdata",
          name: "rfq raw material data",
          component: () => import("@/views/rd/rfq/RawMatData.vue"),
        }),
        new Page({
          path: "/rd/rfq/formulas",
          name: "rfq formulas list",
          component: () => import("@/views/rd/rfq/FormulasList.vue"),
        }),
        new Page({
          path: "/rd/rfq/formulas/:id",
          name: "rfq formula revisions",
          showInNavbar: false,
          component: () => import("@/views/rd/rfq/FormulaRevisions.vue"),
        }),
        new Page({
          path: "/rd/rfq/formulas/:id/:rev/edit",
          name: "rfq formula edit",
          showInNavbar: false,
          component: () => import("@/views/rd/rfq/Formulas.vue"),
        }),
      ],
    }),
    new Page({
      path: "/rd/purchase",
      name: "R&D Procurement",
      visibility: [Role.RDRfq],
      subpages: [
        new Page({
          path: "/rd/purchase/item",
          name: "Add Item",
          component: () => import("@/views/purchase/rd/item/create.vue"),
          subpages: [
            // new Page({
            //   path: "/purchase/itemType/update",
            //   showInNavbar: false,
            //   component: () => import("@/views/purchase/itemType/edit.vue"),
            // }),
            new Page({
              name: "List/Add Item",
              path: "/rd/purchase/item/list",
              component: () => import("@/views/purchase/rd/item/list.vue"),
            }),
            new Page({
              path: "/rd/purchase/item/del",
              name: "delete item",
              // showInNavbar: false,
              component: () => import("@/views/purchase/rd/item/delete.vue"),
            }),
          ],
        }),
        new Page({
          path: "/rd/purchase/edititem/:id",
          name: "Edit Item",
          showInNavbar: false,
          component: () => import("@/views/purchase/rd/edititem.vue"),
        }),
        new Page({
          path: "/rd/purchase/itemprice/:id",
          name: "Add Price",
          showInNavbar: false,
          component: () => import("@/views/purchase/rd/itemprice.vue"),
        }),
        new Page({
          path: "/rd/purchase/analytic",
          name: "Analytic",
          component: () => import("@/views/purchase/rd/analytic.vue"),
        }),
        new Page({
          path: "/rd/purchase/itempricedelete",
          name: "delete item price",
          showInNavbar: false,
          component: () => import("@/views/purchase/rd/itempricedel.vue"),
        }),
        new Page({
          path: "/rd/purchase/analytic/pc",
          name: "Analytic Purchase",
          component: () => import("@/views/purchase/rd/analyticpurchase.vue"),
        }),
      ],
    }),
  ],
});

const registrar = new Page({
  path: "/registrar",
  name: "registrar",
});

const admin = new Page({
  path: "/admin",
  name: "administration",
});

const edoc = new Page({
  path: "/edoc",
  name: "e-documents",
  component: () => import("@/views/edoc/Home.vue"),
  subpages: [
    new Page({
      path: "/edoc/docs/new",
      name: "new e-document",
      component: () => import("@/views/edoc/New.vue"),
    }),
  ],
});

const login = new Page({
  path: "/login",
  name: "login",
  requiresAuth: false,
  showInNavbar: false,
  component: () => import("@/views/Login.vue"),
});
/*
const esko = new Page({
  path: "/esko",
  name: "Esko",
  component: () => import("@/views/esko/esko.vue"),
});
*/

const sale = new Page({
  path: "/sales",
  name: "sales",
  component: () => import("@/views/sales/Sales.vue"),
  subpages: [
    new Page({
      path: "/sales/view",
      name: "sales order list",
      component: () => import("@/views/sales/Salelist.vue"),
    }),
    new Page({
      path: "/sales/status",
      name: "sales order status list",
      component: () => import("@/views/sales/Salelistmg.vue"),
    }),
    new Page({
      path: "/sales/view/:conum([0-9a-z]{10})/:item([0-9a-z]{8})/:line([0-9]{1,2})/:site([0-9a-z]{3})",
      name: "sales order view",
      showInNavbar: false,
      component: () => import("@/views/sales/Saleview.vue"),
    }),
    new Page({
      path: "/sales/view/edit/:conum([0-9a-z]{10})/:item([0-9a-z]{8})/:line([0-9]{1,2})",
      name: "sales order Edit",
      showInNavbar: false,
      component: () => import("@/views/sales/SaleEdit.vue"),
    }),
    new Page({
      path: "/sales/crm",
      name: "crm",
      component: () => import("@/views/sales/crm/Home.vue"),
      subpages: [
        new Page({
          path: "/sales/crm/edit",
          name: "edit",
          component: () => import("@/views/sales/crm/Edit.vue"),
        }),
      ],
    }),
  ],
});

const purchase = new Page({
  path: "/purchase",
  name: "purchase",
  visibility: [Role.Procurement],
  subpages: [
    new Page({
      path: "/purchase/itemType/list",
      name: "List/Add Item Type",
      component: () => import("@/views/purchase/itemType/list.vue"),
      // subpages: [
      //   new Page({
      //     path: "/purchase/itemType/update",
      //     showInNavbar: false,
      //     component: () => import("@/views/purchase/itemType/edit.vue"),
      //   }),
      // ],
    }),
    new Page({
      path: "/purchase/item",
      name: "Add Item",
      component: () => import("@/views/purchase/item/create.vue"),
      subpages: [
        // new Page({
        //   path: "/purchase/itemType/update",
        //   showInNavbar: false,
        //   component: () => import("@/views/purchase/itemType/edit.vue"),
        // }),
        new Page({
          name: "List/Add Item",
          path: "/purchase/item/list",
          component: () => import("@/views/purchase/item/list.vue"),
        }),
        new Page({
          path: "/purchase/item/del",
          name: "delete item",
          showInNavbar: false,
          component: () => import("@/views/purchase/item/delete.vue"),
        }),
      ],
    }),
    new Page({
      path: "/purchase/edititem/:id",
      name: "Edit Item",
      showInNavbar: false,
      component: () => import("@/views/purchase/edititem.vue"),
    }),
    new Page({
      path: "/purchase/itemprice/:id",
      name: "Add Price",
      showInNavbar: false,
      component: () => import("@/views/purchase/itemprice.vue"),
    }),
    new Page({
      path: "/purchase/analytic",
      name: "Analytic",
      component: () => import("@/views/purchase/analytic.vue"),
    }),
    new Page({
      path: "/purchase/itempricedelete",
      name: "delete item price",
      showInNavbar: false,
      component: () => import("@/views/purchase/itempricedel.vue"),
    }),
  ],
});

const it = new Page({
  path: "/it",
  name: "IT",
  component: () => import("@/views/dssm/home.vue"),
  subpages: [
    new Page({
      path: "/accountservice",
      name: "account Service",
      component: () => import("@/views/accountservice/index.vue"),
      subpages: [
        new Page({
          path: "/accountservice/view",
          name: "Information Service Management",
          component: () => import("@/views/accountservice/Aslist.vue"),
          subpages: [
            new Page({
              path: "/accountservice/new",
              name: "new account",
              component: () => import("@/views/accountservice/AsNew.vue"),
            }),
            new Page({
              path: "/accountservice/view/:id([0-9a-f]{24})",
              name: "account Service view",
              showInNavbar: false,
              component: () => import("@/views/accountservice/AsView.vue"),
            }),
          ],
        }),
        new Page({
          path: "/accountservice/view/u",
          name: "Information Account Service Management",
          component: () => import("@/views/accountservice/AsUlist.vue"),
          subpages: [
            new Page({
              path: "/accountservice/new/u",
              name: "new u",
              component: () => import("@/views/accountservice/AsUNew.vue"),
            }),
            new Page({
              path: "/accountservice/view/:id([0-9a-f]{24})/u",
              name: "User view",
              showInNavbar: false,
              component: () => import("@/views/accountservice/AsUView.vue"),
            }),
          ],
        }),
        new Page({
          path: "/accountservice/Admin",
          name: "account Service administration",
          component: () => import("@/views/accountservice/Administration.vue"),
        }),
      ],
    }),
    new Page({
      path: "/guestinternet",
      name: "guest internet list",
      component: () => import("@/views/guestinternet/Internetlist.vue"),
      visibility: [Role.GINTAdministrators],
      subpages: [
        new Page({
          path: "/guestinternet/up",
          name: "upload internet",
          component: () => import("@/views/guestinternet/InternetNew.vue"),
        }),
        new Page({
          path: "/guestinternet/rq",
          name: "request list",
          component: () => import("@/views/guestinternet/requestlist.vue"),
        }),
        new Page({
          path: "/guestinternet/rq/new",
          name: "Register guest internet",
          requiresAuth: false,
          component: () => import("@/views/guestinternet/requestNew.vue"),
        }),
        new Page({
          path: "/guestinternet/rq/fail",
          name: "Register guest internet failed",
          showInNavbar: false,
          requiresAuth: false,
          component: () => import("@/views/guestinternet/requestFailed.vue"),
        }),
        new Page({
          path: "/guestinternet/rq/success/:login/:password",
          name: "Register guest internet success",
          showInNavbar: false,
          requiresAuth: false,
          component: () => import("@/views/guestinternet/requestSuccess.vue"),
        }),
      ],
    }),
    new Page({
      path: "/dssm",
      name: "datacenter and sharecenter",
      component: () => import("@/views/dssm/form/index.vue"),
      subpages: [
        new Page({
          path: "/dssm/form",
          name: "new dssm",
          component: () => import("@/views/dssm/form/index.vue"),
        }),
        new Page({
          path: "/dssm/list",
          name: "list dssm",
          component: () => import("@/views/dssm/form/list.vue"),
        }),
        new Page({
          path: "/dssm/view/:id([0-9a-f]{24})",
          name: "view dssm",
          showInNavbar: false,
          component: () => import("@/views/dssm/form/view.vue"),
        }),
      ],
    }),
    new Page({
      path: "/asset/",
      name: "Asset",
      component: () => import("@/views/asset/add/form.vue"),
      subpages: [
        new Page({
          path: "/asset/it/",
          name: "information asset management",
          showInNavbar: false,
          component: () => import("@/views/asset/information/list.vue"),
          subpages: [
            new Page({
              path: "/asset/it/form/",
              name: "new it",
              component: () => import("@/views/asset/information/form.vue"),
            }),
            new Page({
              path: "/asset/it/view/:id([0-9a-f]{24})",
              name: "it Information Asset Management view (Information)",
              showInNavbar: false,
              component: () => import("@/views/asset/information/view.vue"),
            }),
            new Page({
              path: "/asset/it/editInfo/:id([0-9a-f]{24})",
              name: "edit information asset management",
              showInNavbar: false,
              component: () => import("@/views/asset/information/editInfo.vue"),
            }),
          ],
        }),
        new Page({
          path: "/asset/hw/",
          name: "Hardware Asset Management",
          showInNavbar: false,
          component: () => import("@/views/asset/hardware/list.vue"),
          subpages: [
            new Page({
              path: "/asset/hw/formCN",
              name: "Add Computer And Storage",
              component: () => import("@/views/asset/hardware/form.vue"),
            }),
            new Page({
              path: "/asset/hw/view/:id([0-9a-f]{24})",
              name: "Information Asset Management view (Hardware)",
              showInNavbar: false,
              component: () => import("@/views/asset/hardware/view.vue"),
            }),
            new Page({
              path: "/asset/hw/formP",
              name: "Add Printer",
              component: () => import("@/views/asset/hardware/formPrinter.vue"),
            }),
            new Page({
              path: "/asset/hw/formM",
              name: "Add Monitor",
              component: () => import("@/views/asset/hardware/formMonitor.vue"),
            }),
            new Page({
              path: "/asset/hw/formN",
              name: "Add Network",
              component: () => import("@/views/asset/hardware/formNetwork.vue"),
            }),
            new Page({
              path: "/asset/hw/formU",
              name: "Add UPS",
              component: () => import("@/views/asset/hardware/formUPS.vue"),
            }),
            new Page({
              path: "/asset/hw/formO",
              name: "Add Other",
              component: () => import("@/views/asset/hardware/formOther.vue"),
            }),
            new Page({
              path: "/asset/hw/editCom/:id([0-9a-f]{24})",
              name: "Edit - Computer and Storage",
              showInNavbar: false,
              component: () => import("@/views/asset/hardware/editCom.vue"),
            }),
            new Page({
              path: "/asset/hw/editMonitor/:id([0-9a-f]{24})",
              name: "Edit - Monitor",
              showInNavbar: false,
              component: () => import("@/views/asset/hardware/editMonitor.vue"),
            }),
            new Page({
              path: "/asset/hw/editPrinter/:id([0-9a-f]{24})",
              name: "Edit - Printer",
              showInNavbar: false,
              component: () => import("@/views/asset/hardware/editPrinter.vue"),
            }),
            new Page({
              path: "/asset/hw/editNetwork/:id([0-9a-f]{24})",
              name: "Edit - Network",
              showInNavbar: false,
              component: () => import("@/views/asset/hardware/editNetwork.vue"),
            }),
            new Page({
              path: "/asset/hw/editUPS/:id([0-9a-f]{24})",
              name: "Edit - Uninterruptible Power Supply (UPS)",
              showInNavbar: false,
              component: () => import("@/views/asset/hardware/editUPS.vue"),
            }),
            new Page({
              path: "/asset/hw/editOther/:id([0-9a-f]{24})",
              name: "Edit - Other",
              showInNavbar: false,
              component: () => import("@/views/asset/hardware/editOther.vue"),
            }),
          ],
        }),
        new Page({
          path: "/asset/sw/",
          name: "Software Asset Management",
          showInNavbar: false,
          component: () => import("@/views/asset/software/list.vue"),
          subpages: [
            new Page({
              path: "/asset/sw/form",
              name: "New SW",
              component: () => import("@/views/asset/software/form.vue"),
            }),
            new Page({
              path: "/asset/sw/view/:id([0-9a-f]{24})",
              name: "Information Asset Management view (Software)",
              showInNavbar: false,
              component: () => import("@/views/asset/software/view.vue"),
            }),
            new Page({
              path: "/asset/sw/editSoft/:id([0-9a-f]{24})",
              name: "Edit Software Asset",
              showInNavbar: false,
              component: () => import("@/views/asset/software/editsoft.vue"),
            }),
          ],
        }),
        new Page({
          path: "/asset/vm/",
          name: "Virtual Machines (VM) Asset Management",
          showInNavbar: false,
          component: () => import("@/views/asset/vm/list.vue"),
          subpages: [
            new Page({
              path: "/asset/vm/form",
              name: "new vm",
              component: () => import("@/views/asset/vm/form.vue"),
            }),
            new Page({
              path: "/asset/vm/view/:id([0-9a-f]{24})",
              name: "Information Asset Management view (Virtual Machines (VM))",
              showInNavbar: false,
              component: () => import("@/views/asset/vm/view.vue"),
            }),
            new Page({
              path: "/asset/vm/editVM/:id([0-9a-f]{24})",
              name: "Edit Information Asset Management (Virtual Machines (VM))",
              showInNavbar: false,
              component: () => import("@/views/asset/vm/editVM.vue"),
            }),
          ],
        }),
        new Page({
          path: "/asset/sv/",
          name: "Service Asset Management",
          showInNavbar: false,
          component: () => import("@/views/asset/service/list.vue"),
          subpages: [
            new Page({
              path: "/asset/sv/form/",
              name: "new sv",
              component: () => import("@/views/asset/service/form.vue"),
            }),
            new Page({
              path: "/asset/sv/view/:id([0-9a-f]{24})",
              name: "sv Information Asset Management view (Service)",
              showInNavbar: false,
              component: () => import("@/views/asset/service/view.vue"),
            }),
            new Page({
              path: "/asset/sv/editService/:id([0-9a-f]{24})",
              name: "Edit Information Asset Management",
              showInNavbar: false,
              component: () => import("@/views/asset/service/editService.vue"),
            }),
            new Page({
              path: "/asset/sv/viewIF/:id([0-9a-f]{24})",
              name: "svIF Information Service Management view (ServiceInFo)",
              showInNavbar: false,
              component: () => import("@/views/asset/service/viewIF.vue"),
            }),
          ],
        }),
        new Page({
          path: "/asset/find/",
          name: "Find",
          showInNavbar: false,
          subpages: [
            new Page({
              path: "",
              name: "Hardware",
              subpages: [
                new Page({
                  path: "/asset/find/Computer/",
                  name: "Computer and Storage",
                  component: () => import("@/views/asset/find/findCom/form.vue"),
                }),
                new Page({
                  path: "/asset/find/Moniter/",
                  name: "Moniter",
                  component: () => import("@/views/asset/find/findMoniter/form.vue"),
                }),
                new Page({
                  path: "/asset/find/Network/",
                  name: "Network",
                  component: () => import("@/views/asset/find/findNetwork/form.vue"),
                }),
                new Page({
                  path: "/asset/find/Printer/",
                  name: "Printer",
                  component: () => import("@/views/asset/find/findPrint/form.vue"),
                }),
                new Page({
                  path: "/asset/find/Ups/",
                  name: "Ups",
                  component: () => import("@/views/asset/find/findUps/form.vue"),
                }),
                new Page({
                  path: "/asset/find/Other/",
                  name: "Other",
                  component: () => import("@/views/asset/find/findOther/form.vue"),
                }),
              ],
            }),

            new Page({
              path: "/asset/find/Software/",
              name: "Software",
              component: () => import("@/views/asset/find/findSoft/form.vue"),
            }),
            new Page({
              path: "/asset/find/Vmware/",
              name: "VMWare",
              component: () => import("@/views/asset/find/findVM/form.vue"),
            }),
            new Page({
              path: "",
              name: "Service",
              subpages: [
                new Page({
                  path: "/asset/find/Service/",
                  name: "Service",
                  component: () => import("@/views/asset/find/findService/form.vue"),
                }),
                new Page({
                  path: "/asset/find/ServiceInfo/",
                  name: "ServiceInfo",
                  component: () => import("@/views/asset/find/findServiceInfo/form.vue"),
                }),
              ],
            }),
            new Page({
              path: "/asset/find/Information/",
              name: "Information",
              component: () => import("@/views/asset/find/findInfo/form.vue"),
            }),
            new Page({
              path: "/asset/find/Equipment/",
              name: "Equipment",
              component: () => import("@/views/asset/find/findEquipment/form.vue"),
            }),
          ],
        }),
        new Page({
          path: "/asset/addType/",
          name: "Add Harware Type",
          component: () => import("@/views/asset/type/form.vue"),
        }),
        new Page({
          path: "/asset/addDepartment/",
          name: "Add Department",
          component: () => import("@/views/asset/department/form.vue"),
        }),
        new Page({
          path: "/asset/addTypeSoft/",
          name: "Add Software Type",
          component: () => import("@/views/asset/typesoft/form.vue"),
        }),
        new Page({
          path: "/asset/addTypeVm/",
          name: "Add Virtual Machine Type",
          component: () => import("@/views/asset/typevm/form.vue"),
        }),
        new Page({
          path: "/asset/addTypeRam/",
          name: "Add RAM",
          component: () => import("@/views/asset/typeram/form.vue"),
        }),
        new Page({
          path: "/asset/addTypeHarddisk/",
          name: "Add Harddisk",
          component: () => import("@/views/asset/typeharddisk/form.vue"),
        }),
        new Page({
          path: "/asset/addTypeVmHarddisk/",
          name: "Add Virtual Machine Harddisk",
          component: () => import("@/views/asset/typevmharddisk/form.vue"),
        }),
        new Page({
          path: "/asset/admin/",
          name: "Asset administration",
          component: () => import("@/views/asset/Administration.vue"),
        }),
      ],
    }),
  ],
});

const xml = new Page({
  path: "/esko/",
  name: "esko",
  subpages: [
    new Page({
      path: "/rfq/",
      name: "RFQ To Esko",
      component: () => import("@/views/esko/rfq.vue"),
    }),
    new Page({
      path: "/esko/xml/",
      name: "Esko Xml",
      component: () => import("@/views/esko/esko.vue"),
    }),
    new Page({
      path: "/esko/newproduct/",
      name: "new product",
      showInNavbar: false,
      component: () => import("@/views/esko/view/newproduct.vue"),
    }),
    new Page({
      path: "/esko/inkjetproof/",
      name: "inkjet proof",
      showInNavbar: false,
      component: () => import("@/views/esko/view/inkjetproof.vue"),
    }),
    new Page({
      path: "/esko/xml/list",
      name: "Esko Xml List",
      component: () => import("@/views/esko/eskoList.vue"),
    }),
  ],
});

const messages = new Page({
  path: "/messages",
  name: "Messages",
  showInNavbar: false,
  component: () => import("@/views/messages/Messages.vue"),
});

const security = new Page({
  path: "/security",
  name: "security",
  subpages: [
    new Page({
      path: "/security/admin",
      name: "security administration",
      component: () => import("@/views/security/Administration.vue"),
    }),
    new Page({
      path: "/security/logs",
      name: "logs",
      visibility: [Role.LogReviewers],
      subpages: [
        new Page({
          path: "/security/logs/dashboard",
          name: "logs dashboard",
          component: () => import("@/views/security/logs/Dashboard.vue"),
        }),
      ],
    }),
    new Page({
      path: "/security/keyManagement",
      name: "key management",
      visibility: [Role.KMManagers, Role.KMUsers],
      subpages: [
        new Page({
          path: "/security/keyManagement/hosts",
          name: "km hosts",
          component: () => import("@/views/security/km/Hosts.vue"),
        }),
        new Page({
          path: "/security/keyManagement/keys",
          name: "km keys",
          component: () => import("@/views/security/km/Keys.vue"),
        }),
        new Page({
          path: "/security/keyManagement/associations",
          name: "km associations",
          showInNavbar: false,
          component: () => import("@/views/security/km/Associations.vue"),
        }),
        new Page({
          path: "/security/networkLocations",
          name: "network locations",
          showInNavbar: false,
          component: () => import("@/views/security/networkLocations/IndexView.vue"),
        }),
      ],
    }),
  ],
});

const order = new Page({
  path: "/order",
  name: "Job Order THS To Syte line",
  visibility: [Role.JOBOrderTHS],
  showInNavbar: true,
  component: () => import("@/views/order2syteline/list.vue"),
  subpages: [
    new Page({
      path: "/order/form/:id([0-9a-f]{6})",
      name: "edit Job Order THS To Syte line",
      showInNavbar: false,
      component: () => import("@/views/order2syteline/form.vue"),
    }),
    new Page({
      path: "/order/approve",
      name: "Job Order to Syte Line Approve",
      component: () => import("@/views/order2syteline/listMongo.vue"),
    }),
    new Page({
      path: "/order/edit",
      name: "Job Order to Syte Line Edit",
      component: () => import("@/views/order2syteline/listEdit.vue"),
    }),
    new Page({
      path: "/order/formEdit/:id([0-9a-f]{6})",
      name: "Job Order THS To Syte line Edit PO",
      showInNavbar: false,
      component: () => import("@/views/order2syteline/formEdit.vue"),
    }),
  ],
});

const tpk = new Page({
  path: "/tpk",
  name: "tpk",
  component: () => import("@/views/tpk/TPKView.vue"),
  subpages: [
    new Page({
      path: "/tpk/rfq",
      name: "tpk rfq",
      component: () => import("@/views/tpk/rfq/ListView.vue"),
      subpages: [
        new Page({
          path: "/tpk/rfq/edit",
          name: "edit rfq",
          component: () => import("@/views/tpk/rfq/EditView.vue"),
          showInNavbar: false,
        }),
      ],
    }),
    new Page({
      path: "/tpk/artjob",
      name: "art job",
      component: () => import("@/views/tpk/artjob/ArtJobList.vue"),
      subpages: [
        new Page({
          path: "/tpk/artjob/edit",
          name: "edit art job",
          showInNavbar: false,
          component: () => import("@/views/tpk/artjob/ArtJobForm.vue"),
        }),
        new Page({
          path: "/tpk/artjob/meta",
          name: "art job meta",
          showInNavbar: false,
          component: () => import("@/views/tpk/artjob/ArtJobMeta.vue"),
        }),
        new Page({
          path: "/tpk/artjob/parameters",
          name: "art job parameters",
          component: () => import("@/views/tpk/artjob/Parameters.vue"),
        }),
      ],
    }),
    new Page({
      name: "die line",
      path: "/tpk/dieline",
      component: () => import("@/views/tpk/DieLine.vue"),
    }),
  ],
});

const OrderRequest = new Page({
  path: "/OrderRequest",
  name: "Purchase Order Request",
  // component: () => import("@/views/accApprovePR/Approves/form.vue"),
  subpages: [
    new Page({
      path: "/OrderRequest/Purchase",
      name: "Purchase",
      component: () => import("@/views/accApprovePR/Approves/Purchase.vue"),
      visibility: [Role.PurchaseAdministrators],
    }),
    new Page({
      path: "/OrderRequest/Manager",
      name: "Approver",
      component: () => import("@/views/accApprovePR/Approves/Manager.vue"),
    }),
    new Page({
      path: "/OrderRequest/UserApprove",
      name: "User",
      component: () => import("@/views/accApprovePR/Approves/UserApprove.vue"),
    }),
    new Page({
      path: "/OrderRequest/AttachedFiles/:site([a-z]{3,4})/:req_num([0-9a-z*]{10})",
      name: "AttachFiles",
      showInNavbar: false,
      component: () => import("@/views/accApprovePR/AttachedFiles/form.vue"),
    }),
    new Page({
      path: "/OrderRequest/AttachedFiles/ViewFiles/:site([a-z]{3,4})/:req_num([0-9a-z*]{10})",
      name: "ViewFiles",
      showInNavbar: false,
      component: () => import("@/views/accApprovePR/AttachedFiles/viewfile.vue"),
    }),
    new Page({
      path: "/OrderRequest/EditItemline/:site([a-z]{3,4})/:req_num([0-9a-z*]{10})/:req_line([0-9]{1,2})",
      name: "EditItemline",
      showInNavbar: false,
      component: () => import("@/views/accApprovePR/AttachedFiles/EditItem.vue"),
    }),
    new Page({
      path: "/OrderRequest/ViewOrderRequest/:site([a-z]{3,4})/:req_num([0-9a-z*]{10})",
      name: "ViewOrderRequest",
      showInNavbar: false,
      component: () => import("@/views/accApprovePR/AttachedFiles/viewPr.vue"),
    }),
  ],
});

homepage.children.push(rnd, registrar, admin, edoc, login, sale, xml, messages, security, it, purchase, order, tpk, OrderRequest);

const internetResources = new Page({
  path: "/registrar/internet",
  name: "internet resources",
  component: () => import("@/views/eir/EIR.vue"),
});

registrar.children.push(internetResources);

const eirSubnet = new Page({
  path: "/registrar/internet/subnet",
  name: "subnet resources",
  component: () => import("@/views/eir/I4Subnet.vue"),
});

internetResources.children.push(eirSubnet);

const org = new Page({
  path: "/admin/organizations",
  name: "organizations",
  component: () => import("@/views/org/OrganizationsView.vue"),
});

const id = new Page({
  path: "/admin/id",
  name: "identities administration",
  component: () => import("@/views/id/Identities.vue"),
  subpages: [
    new Page({
      path: "/id",
      name: "my identity",
      component: () => import("@/views/id/MyIdentity.vue"),
      subpages: [
        new Page({
          path: "/id/line",
          name: "line",
          component: () => import("@/views/id/Line.vue"),
        }),
      ],
    }),
    new Page({
      path: "/admin/entities",
      name: "entities administration",
      component: () => import("@/views/id/Entities.vue"),
    }),
    new Page({
      path: "/admin/users",
      name: "user accounts administration",
      component: () => import("@/views/id/Users.vue"),
    }),
    new Page({
      path: "/admin/groups",
      name: "groups administration",
      component: () => import("@/views/id/Groups.vue"),
    }),
    new Page({
      path: "/admin/members",
      name: "group membership administration",
      component: () => import("@/views/id/GroupMembers.vue"),
      showInNavbar: false,
    }),
    new Page({
      path: "/admin/session",
      name: "my session",
      component: () => import("@/views/id/Session.vue"),
    }),
  ],
});

const email = new Page({
  path: "/email",
  name: "e-mail",
  component: () => import("@/views/email/Domain.vue"),
  subpages: [
    new Page({
      path: "/email/mailboxes",
      name: "mailboxes",
      component: () => import("@/views/email/Mailbox.vue"),
    }),
    new Page({
      path: "/email/createFromDir",
      name: "create from directory",
      component: () => import("@/views/email/CreateFromDir.vue"),
    }),
    new Page({
      path: "/email/aliases",
      name: "aliases",
      component: () => import("@/views/email/Aliases.vue"),
    }),
    new Page({
      path: "/email/mailboxes/disable",
      name: "disable mailbox",
      component: () => import("@/views/email/DisableMailbox.vue"),
    }),
  ],
});

const apiTools = new Page({
  path: "/dev/apitools",
  name: "API tools",
  component: () => import("@/views/APITools.vue"),
  subpages: [
    new Page({
      path: "/dev/apitools/multiFileTest",
      name: "MultiFileUploadControl test",
      component: () => import("@/views/admin/TestMultiFile.vue"),
    }),
  ],
});

const dir = new Page({
  path: "/admin/directory",
  name: "directory",
  component: () => import("@/views/id/dir/TabbedView.vue"),
  subpages: [
    new Page({
      path: "/admin/directory/contacts",
      name: "contacts",
      component: () => import("@/views/org/ContactsView.vue"),
    }),
  ],
});

admin.children.push(
  dir, org, id, email,
  new Page({
    path: "/llce/bookmarks",
    name: "bookmarks",
    component: () => import("@/views/admin/BookmarksView.vue"),
  }),
  new Page({
    path: "/admin/stats",
    name: "runtime statistics",
    component: () => import("@/views/admin/stats.vue"),
  }),
  apiTools,
);

const subnet = new Page({
  path: "/registrar/internet/subnet",
  name: "subnet",
});

internetResources.children.push(subnet);
